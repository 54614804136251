(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:EntityTeams
   *
   * @description
   *
   */
  angular
  .module('neo.services')
  .factory('EntityTeams', EntityTeams);

  function EntityTeams($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/entities/:entityId/teams', {entityId: '@_entityId'}, {
      update: {
        method: 'PUT'
      }
    });
  }
}());
